let svg;
let shops;
let svgShops;
let activeShop;
let bubble;

const handleSvgShopClick = (shop) => {
  const href = shop.getAttribute('href');

  if (href) {
    location.href = href;
    return;
  }

  showShop(shop);
};

const handleBubbleClick = (e) => {
  const shop = document.querySelector(`[data-shop][data-number="${e.currentTarget.getAttribute('data-number')}"]`);

  if (!shop) {
    return;
  }

  const href = shop.getAttribute('href');

  if (href) {
    location.href = href;
    return;
  }

  showShop(shop);
};

const initPlans = () => {
  shops = document.querySelectorAll('[data-shop]');
  svg = document.querySelector('.shop-plan-container svg');

  if (shops.length === 0 || !svg) {
    return;
  }

  svgShops = svg.querySelectorAll('[data-number]');
  bubble = document.querySelector('#shop-name-container');

  const vacantNumbers = [];

  for (const shop of shops) {
    if (shop.getAttribute('data-vacant') === 'true') {
      vacantNumbers.push(shop.getAttribute('data-number'));
    }

    shop.addEventListener('click', () => {
      showShop(shop);
    });

    shop.addEventListener('touchstart', () => {
      showShop(shop);
    });

    shop.addEventListener('mouseover', () => {
      highlightShop(shop);
    });

    shop.addEventListener('mouseout', () => {
      unhighlightShop();
    });
  }

  for (const svgShop of svgShops) {
    if (vacantNumbers.includes(svgShop.getAttribute('data-number'))) {
      svgShop.style.fill = '#c5dadd';
      svgShop.setAttribute('data-vacant', 'true');
    }

    const shop = document.querySelector(`[data-shop][data-number="${svgShop.getAttribute('data-number')}"]`);

    if (!shop) {
      continue;
    }

    svgShop.addEventListener('mouseover', () => {
      highlightShop(shop);
    });

    svgShop.addEventListener('mouseout', (e) => {
      if (e.toElement !== bubble.querySelector('span')) {
        unhighlightShop();
      }
    });

    svgShop.addEventListener('click', () => {
      handleSvgShopClick(shop);
    });

    svgShop.addEventListener('touchstart', () => {
      handleSvgShopClick(shop);
    });
  }

  if (bubble) {
    bubble.addEventListener('click', (e) => {
      handleBubbleClick(e);
    });

    bubble.addEventListener('touchstart', (e) => {
      handleBubbleClick(e);
    });

    bubble.addEventListener('mouseout', () => {
      unhighlightShop();
    });
  }
};

const clearActiveShops = () => {
  for (const activeShop of document.querySelectorAll('[data-shop]')) {
    activeShop.parentElement.classList.remove('rental-nav__item--active');
    activeShop.parentElement.classList.remove('shop-nav__item--active');
    activeShop.parentElement.classList.remove('building-nav__item--active');
  }
};

const clearSvg = () => {
  for (const element of svg.querySelectorAll('[data-number]')) {
    if (element.getAttribute('data-vacant') !== 'true') {
      element.style.fill = '';
    }
  }

  for (const element of svg.querySelectorAll('[data-door]')) {
    if (element.getAttribute('data-vacant') !== 'true') {
      element.style.fill = '';
    }
  }

  for (const element of svg.querySelectorAll('[data-hide]')) {
    element.style.display = '';
  }
};

const showShopData = (shop) => {
  for (const element of document.querySelectorAll('.shop-name-container span')) {
    element.innerHTML = shop.getAttribute('data-name');
  }

  for (const element of document.querySelectorAll('.shop-description-container')) {
    element.innerHTML = shop.getAttribute('data-description');
  }

  const buttonUrl = shop.getAttribute('data-button-url');
  const buttonName = shop.getAttribute('data-button-name');

  for (const element of document.querySelectorAll('.shop-button-container')) {
    if (buttonUrl && buttonName) {
      element.innerHTML = `
        <a href="${buttonUrl}" target="_blank" class="button button--yellow">${buttonName}</a>
      `;
    } else {
      element.innerHTML = '';
    }
  }

  const imagePath = shop.getAttribute('data-image');

  for (const element of document.querySelectorAll('.shop-image-container')) {

    if (imagePath) {
      const image = document.createElement('img');
      image.setAttribute('src', imagePath);

      element.innerHTML = image.outerHTML;
    } else {
      element.innerHTML = '';
    }
  }

  if (shop.parentElement.classList.contains('building-nav__item')) {
    shop.parentElement.classList.add('building-nav__item--active');
  }

  if (shop.parentElement.classList.contains('shop-nav__item')) {
    shop.parentElement.classList.add('shop-nav__item--active');
  }

  if (shop.parentElement.classList.contains('rental-nav__item')) {
    shop.parentElement.classList.add('rental-nav__item--active');
  }

  shop.blur();
};

const showShopSvg = (shop) => {
  const element = svg.querySelector(`[data-number="${shop.getAttribute('data-number')}"]`);

  if (element) {
    element.style.cursor = 'pointer';

    if (element.getAttribute('data-vacant') !== 'true') {
      element.style.fill = '#eacc50';
    }

    const bbox = element.getBoundingClientRect();

    bubble.children[0].innerText = shop.getAttribute('data-name');
    bubble.style.top = `${window.scrollY + bbox.top + bbox.height / 4}px`;
    bubble.style.left = `${bbox.left + bbox.width / 2}px`;
    bubble.style.display = 'block';
    bubble.style.transform = 'translate(-50%, -50%)';
    bubble.style.cursor = 'pointer';

    bubble.setAttribute('data-number', shop.getAttribute('data-number'));
  }

  for (const door of svg.querySelectorAll(`[data-door="${shop.getAttribute('data-number')}"]`)) {
    door.style.fill = '#000000';
  }

  for (const door of svg.querySelectorAll(`[data-door2="${shop.getAttribute('data-number')}"]`)) {
    door.style.fill = '#000000';
  }

  for (const door of svg.querySelectorAll(`[data-number2="${shop.getAttribute('data-number')}"]`)) {
    door.style.fill = '#eacc50';
  }
};

const highlightShop = (shop) => {
  clearSvg();
  showShopSvg(shop);
};

const unhighlightShop = () => {
  if (activeShop) {
    highlightShop(activeShop);
    return;
  }

  clearSvg();

  bubble.style.display = 'none';
};

const showShop = (shop) => {
  activeShop = shop;

  clearActiveShops();
  showShopData(shop);
  highlightShop(shop);
};

document.addEventListener('turbolinks:load', () => {
  initPlans();
});

document.addEventListener('turbolinks:before-cache', () => {
  svg = null;
  shops = null;
  svgShops = null;
  activeShop = null;
  bubble = null;
});