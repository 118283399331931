require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('src/plans');
require('vendors/flipbook');

import Swiper, { Navigation, Autoplay } from 'swiper';
Swiper.use([Navigation, Autoplay]);

const initMainNav = () => {
  const hamgurger = document.getElementById('main-nav-toggle');
  const mainNav = document.querySelector('.main-nav');

  hamgurger.addEventListener('click', () => {
    hamgurger.classList.toggle('is-active');
    mainNav.classList.toggle('main-nav--open');
    document.body.classList.toggle('main-nav-open')
  });

  for (const subnav of mainNav.querySelectorAll('.main-nav__list .main-nav__list')) {
    subnav.parentElement.querySelector('a').addEventListener('click', (e) => {
      if (window.innerWidth < 992) {
        e.preventDefault();

        e.currentTarget.parentElement.classList.toggle('main-nav__list--open');
      }
    });
  }
};

const initSliders = () => {
  const promotionAutoplay = document.querySelectorAll('#promotions-swiper .swiper-slide').length > 3 ? {
    delay: 5000
  } : false;

  new Swiper('#promotions-swiper .swiper-container', {
    loop: true,
    spaceBetween: 19,
    slidesPerView: 1,
    autoplay: promotionAutoplay,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
    navigation: {
      nextEl: document.querySelector('#promotions-swiper .swiper-button-next'),
      prevEl: document.querySelector('#promotions-swiper .swiper-button-prev'),
    },
  });

  new Swiper('#categories-swiper .swiper-container', {
    loop: true,
    slidesPerView: 3,
    breakpoints: {
      400: {
        slidesPerView: 4,
      },
      600: {
        slidesPerView: 5,
      },
      768: {
        slidesPerView: 7,
      },
      1200: {
        slidesPerView: 9,
      },
    },
    navigation: {
      nextEl: document.querySelector('#categories-swiper .swiper-button-next'),
      prevEl: document.querySelector('#categories-swiper .swiper-button-prev'),
    },
  });

  if (document.querySelectorAll('#hero-swiper .swiper-slide').length > 1) {console.log(document.querySelector('#hero-swiper').hasAttribute('data-autoplay'));
    new Swiper('#hero-swiper .swiper-container', {
      loop: true,
      slidesPerView: 1,
      autoplay: document.querySelector('#hero-swiper').hasAttribute('data-autoplay') ? { delay: 5000 } : false,
      navigation: {
        nextEl: document.querySelector('#hero-swiper .swiper-button-next'),
        prevEl: document.querySelector('#hero-swiper .swiper-button-prev'),
      },
    });
  }

  if (document.querySelectorAll('#news-swiper .swiper-slide').length > 1) {
    new Swiper('#news-swiper .swiper-container', {
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: document.querySelector('#news-swiper .swiper-button-next'),
        prevEl: document.querySelector('#news-swiper .swiper-button-prev'),
      },
    });
  }
};

const initHeaderOffset = () => {
  const header = document.querySelector('.header');
  const logo = document.querySelector('.header__logo');
  const offset = Math.max(30, logo.offsetHeight - header.offsetHeight + 30);

  const element = document.querySelector('.-header-offset');

  if (element) {
    element.style.marginTop = `${offset}px`;
  }
};

const initFlipBook = () => {
  const element = document.getElementById('flipbook');

  if (!element) {
    return;
  }

  const container = document.querySelector('.flipbook-container');

  container.style.display = 'none';

  new FlipBook('flipbook', {
    canClose: true,
    initialCall: true,
    arrowKeys: true,
    nextButton: document.getElementById('flipbook-next'),
    previousButton: document.getElementById('flipbook-prev')
  });

  setTimeout(() => {
    container.style.display = 'block';
  }, 0);
}

document.addEventListener('turbolinks:load', () => {
  initMainNav();
  initSliders();
  initHeaderOffset();
  initFlipBook();

  window.addEventListener('resize', initHeaderOffset);
});